<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">About Controls</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container class="my-5">
          <div>Beta version of the Controls Model Reference. Contact Lucas Ocelewicz for more information.</div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$stat.log({ page: 'about controls', action: 'open about controls' })
  }
}
</script>
